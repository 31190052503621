/* eslint-disable @typescript-eslint/ban-ts-comment */
import FingerprintJS from "@fingerprintjs/fingerprintjs";

const openDatabase = () => {
    return new Promise((resolve, reject) => {
        const request = window.indexedDB.open('myDatabase', 1);

        request.onerror = () => {
            reject(request.error);
        };

        request.onsuccess = () => {
            resolve(request.result);
        };

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        // request.onupgradeneeded = (event) => {
        //     const db = event.target.result;
        //     db.createObjectStore('secrets', { keyPath: 'key' });
        // };

        request.onupgradeneeded = (event) => {
            // @ts-ignore
            const db = event.target.result;
            if (!db.objectStoreNames.contains('secrets')) {
                db.createObjectStore('secrets', { keyPath: 'key' });
            }
        };
    });
}

// const getDeviceSecret = async () => {
//     const db = await openDatabase();

//     return new Promise((resolve, reject) => {
//         const transaction = db.transaction(['secrets'], 'readwrite');
//         const objectStore = transaction.objectStore('secrets');
//         const request = objectStore.get('deviceSecret');

//         request.onsuccess = async () => {
//             if (request.result) {
//                 resolve(request.result.value);
//             } else {
//                 console.log("------------------------------------------")
//                 const fpPromise = FingerprintJS.load();
//                 const fp = await fpPromise;
//                 const result = await fp.get();
//                 const deviceIdToSend = result.visitorId;
//                 console.log(deviceIdToSend);
//                 const deviceSecret = deviceIdToSend;
//                 objectStore.add({ key: 'deviceSecret', value: deviceSecret });
//                 resolve(deviceSecret);
//             }
//         };

//         request.onerror = () => {
//             reject(request.error);
//         };
//     });
// }


const getDeviceSecret = async () => {
    const db = await openDatabase();

    // First, try to read the secret from the database
    const readSecret = () =>
        new Promise((resolve, reject) => {
            const transaction = db.transaction(['secrets'], 'readonly');
            const objectStore = transaction.objectStore('secrets');
            const request = objectStore.get('deviceSecret');

            request.onsuccess = () => {
                if (request.result) {
                    resolve(request.result.value);
                } else {
                    resolve(undefined);
                }
            };

            request.onerror = () => {
                reject(request.error);
            };
        });

    const existingSecret = await readSecret();

    if (existingSecret) {
        return existingSecret;
    }

    // If not found, perform the asynchronous fingerprint operation
    const fpPromise = FingerprintJS.load();
    const fp = await fpPromise;
    const result = await fp.get();
    const deviceSecret = result.visitorId;
    console.log("Generated device secret:", deviceSecret);

    // Now, open a new transaction to store the secret
    await new Promise((resolve, reject) => {
        const transaction = db.transaction(['secrets'], 'readwrite');
        const objectStore = transaction.objectStore('secrets');
        const request = objectStore.add({ key: 'deviceSecret', value: deviceSecret });

        request.onsuccess = () => {
            resolve();
        };

        request.onerror = () => {
            reject(request.error);
        };
    });

    return deviceSecret;
};

export default getDeviceSecret